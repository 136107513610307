/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
// src/redux/slices/cartSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [], // Initial empty cart
  orderOption: '', // Ajout de l'option de commande
  address: '', // Ajout de l'adresse
  city: '', // Ajout de la ville
  restoId: 0, // Ajout de la ville
  latResto: 0, // Ajout de la ville
  longResto: 0, // Ajout de la ville
  latUser: 0, // Ajout de la ville
  longUser: 0, // Ajout de la ville
  distance: 0, // Ajout de la ville
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // eslint-disable-next-line camelcase
      const { name, image, product_id, price, quantity, total, orderId } = action.payload;
      state.cartItems.push({
        name,
        image,
        product_id,
        price,
        quantity,
        total,
        orderId,
      });
    },
    removeFromCart: (state, action) => {
      // eslint-disable-next-line camelcase
      const { product_id } = action.payload;
    
      // Recherchez l'index de l'article dans le panier
      // eslint-disable-next-line camelcase
      const indexToRemove = state.cartItems.findIndex((item) => item.product_id === product_id);
    
      // Vérifiez si l'article a été trouvé
      if (indexToRemove !== -1) {
        // Supprimez l'article du panier en utilisant splice
        state.cartItems.splice(indexToRemove, 1);
      } else {
        // eslint-disable-next-line camelcase
        console.error(`Produit avec l'ID "${product_id}" non trouvé dans le panier.`);
      }
    },   
    modifyFromCart: (state, action) => {
      // eslint-disable-next-line camelcase
      const { product_id, quantity } = action.payload;
    
      // Trouvez l'index de l'article dans le panier
      // eslint-disable-next-line camelcase
      const indexToModify = state.cartItems.findIndex((item) => item.product_id === product_id);
    
      // Vérifiez si l'article a été trouvé
      if (indexToModify !== -1) {
        // Modifiez la quantité de l'article dans le panier
        state.cartItems[indexToModify].quantity += quantity;
    
        // Recalculer le total du produit
        state.cartItems[indexToModify].total = state.cartItems[indexToModify].price * state.cartItems[indexToModify].quantity;
      } else {
        // eslint-disable-next-line camelcase
        console.error(`Produit avec l'ID "${product_id}" non trouvé dans le panier.`);
      }
    },     
    setOrderOptionRedux: (state, action) => {
      state.orderOption = action.payload;
    },
    setAddressRedux: (state, action) => {
      state.address = action.payload;
    },
    setCityRedux: (state, action) => {
      state.city = action.payload;
    },
    setRestoIdRedux: (state, action) => {
      state.restoId = action.payload;
    },
    setLatRestoRedux: (state, action) => {
      state.latResto = action.payload;
    },
    setLongRestoRedux: (state, action) => {
      state.longResto = action.payload;
    },
    setLatUserRedux: (state, action) => {
      state.latUser = action.payload;
    },
    setLongUserRedux: (state, action) => {
      state.longUser = action.payload;
    },
    setDistanceRedux: (state, action) => {
      state.distance = action.payload;
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.orderOption = '';
      state.address = '';
      state.city = '';
      state.restoId = 0;
      state.latResto = 0;
      state.longResto = 0;
      state.latUser = 0;
      state.longUser = 0;
      state.distance = 0;
    },
    // Ajoutez d'autres cas pour gérer le panier (modification, vidage, etc.)
  },
});

export const { addToCart, removeFromCart, modifyFromCart, setOrderOptionRedux, setAddressRedux, setCityRedux, setRestoIdRedux, setLatRestoRedux, setLongRestoRedux, setLatUserRedux, setLongUserRedux, setDistanceRedux, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
